import { Outlet } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";

import React, { useState } from "react";

export const ProtectedTemplate = () => {
	const [open, setOpen] = useState(false); // Utiliza useState para inicializar el estado
	const toggleOpen = () => {
		setOpen(!open);
	};

	return (
		<>
			<Navbar toggleOpen={toggleOpen} />
			<div className="d-flex align-items-stretch">
				<Sidebar isOpen={open} />
				<div className="page-holder bg-gray-500">
					<div className="container-fluid px-lg-4 px-xl-5">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
};
