import React, { useEffect, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { usePocket } from "../contexts/PocketContext";
function RedirectPage() {
	const { loginWithProvider, user } = usePocket();

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const hanleLogin = useCallback(
		async (evt) => {
			try {
				if (user != null) {
					navigate("/home");

					return;
				}

				if (searchParams.get("code") == null || searchParams.get("state") == null) {
					navigate("/login");
					return;
				}

				await loginWithProvider(searchParams.get("code"));
				// await pocketbase.collection("users").update(id, { email: rawUser.email, name: rawUser.name });

				navigate("/home");
			} catch (error) {
				navigate("/login");
			}
		},
		[user, loginWithProvider, searchParams, navigate]
	);
	// Llama a hanleLogin automáticamente al cargar la página
	useEffect(() => {
		hanleLogin();
	}, [hanleLogin]);

	return (
		<>
			<h1 className="h4 text-uppercase text-white mb-5">Bienvenido de nuevo 👋👋</h1>
			<h2 className="h5 text-muted text-white text-center">
				<i className="fas fa-spinner fa-spin"></i> Espere...
			</h2>
		</>
	);
}

export default RedirectPage;
