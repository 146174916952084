import { Outlet } from "react-router-dom";
import logo from "../img/contacta_blanco.png";

export const LoginTemplate = () => (
	<div className="container-fluid px-0 overflow-x-hidden bg-gray-dark">
		<div className="row gx-0 min-vh-100">
			<div className="col-md-9 col-lg-6 col-xl-4 px-5 d-flex align-items-center shadow">
				<div className="w-100 py-5 text-center">
					<div className="text-center">
						<img className="img-fluid mb-0" src={logo} alt="logo" style={{ maxWidth: "12.5rem" }} />
					</div>

					<Outlet />
				</div>
			</div>
			<div className="col-md-3 col-lg-6 col-xl-8 d-none d-md-block">
				<div className="bg-cover h-100 me-n3" style={{ backgroundImage: "url(https://source.unsplash.com/random/?mountain,night)" }}></div>
			</div>
		</div>
	</div>
);
