import React from "react";
import { usePocket } from "../contexts/PocketContext";

function HomePage() {
	const { user } = usePocket();
	return (
		<>
			<div className="page-header">
				<h1 className="page-heading">Default dashboard</h1>
			</div>
			<section className="mb-3 mb-lg-5">
				<div className="row mb-3">
					<h1>Home</h1>
					<pre>
						<code>{JSON.stringify(user, null, 2)}</code>
					</pre>
				</div>
			</section>
		</>
	);
}

export default HomePage;
