import { Link } from "react-router-dom";

const NotFoundPage = () => {
	return (
		<>
			<h1 className="h4 text-uppercase text-white mb-5">No encontrado! 😟</h1>
			<Link className="btn btn-primary" to={{ pathname: "home" }}>
				<h2 className="h4 text-uppercase text-white"> Inicio</h2>
			</Link>
		</>
	);
};

export default NotFoundPage;
