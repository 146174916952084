import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./styles/bubbly.css";
import "./styles/style.css";

import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RedirectPage from "./pages/RedirectPage";
import NotFoundPage from "./pages/NotFoundPage";

import { RequireAuth } from "./composables/RequireAuth";
import { ProtectedTemplate } from "./templates/ProtectedTemplate";
import { LoginTemplate } from "./templates/LoginTemplate";
import { PocketProvider } from "./contexts/PocketContext";

function App() {
	return (
		<PocketProvider>
			<BrowserRouter>
				<Routes>
					<Route element={<ProtectedTemplate />}>
						<Route element={<RequireAuth />}>
							<Route index element={<HomePage />} />
							<Route path="/home" element={<HomePage />} />
						</Route>
					</Route>

					<Route element={<LoginTemplate />}>
						<Route path="/login" element={<LoginPage />} />
						<Route path="/redirect" element={<RedirectPage />} />
						<Route path="*" element={<NotFoundPage />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</PocketProvider>
	);
}

export default App;
