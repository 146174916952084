import { Link } from "react-router-dom";
import logo from "../img/contacta_blanco.png";
import { usePocket } from "../contexts/PocketContext";
import { useState } from "react";

const Navbar = ({ toggleOpen }) => {
	const { user, logout } = usePocket();
	const [open, setOpen] = useState(false);

	const handleDropdown = () => {
		setOpen(!open);
	};

	return (
		<header className="header">
			<nav className="navbar navbar-expand-lg px-4 py-2 bg-dark shadow">
				<a className="sidebar-toggler text-gray-500 me-4 me-lg-5 lead" href="#open" onClick={toggleOpen}>
					<i className="fas fa-bars"></i>
				</a>

				<Link className="navbar-brand fw-bold text-uppercase text-base" to="/home">
					<span className="d-none d-brand-partial">
						<img src={logo} alt="Logo" style={{ maxWidth: "10rem" }} />
					</span>
				</Link>
				<ul className="ms-auto d-flex align-items-center list-unstyled mb-0">
					<li className="nav-item dropdown ms-auto">
						<a className={`nav-link pe-0 ${open ? "show" : ""}`} id="userInfo" href="#dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded={open ? "false" : "true"} onClick={handleDropdown}>
							<img className="avatar" src={`https://ui-avatars.com/api/?name=${encodeURI(user?.username)}&background=0D8ABC&color=fff&size=128`} alt={user?.username} />
						</a>
						<div className={`dropdown-menu dropdown-menu-end dropdown-menu-animated ${open ? "show" : ""}`} aria-labelledby="userInfo" data-bs-popper={open ? "static" : ""}>
							<div className="dropdown-header text-gray-700">
								<h6 className="text-uppercase font-weight-bold">{user?.name}</h6>
								<small>{user?.email}</small>
							</div>

							<div className="dropdown-divider"></div>
							<Link className="dropdown-item" to="/log">
								<i className="fa fa-chart-line"></i> Log
							</Link>

							<div className="dropdown-divider"></div>
							<Link className="dropdown-item" to="/settings">
								<i className="fa fa-cog"></i> Ajustes
							</Link>
							<Link className="dropdown-item" to="/profile">
								<i className="fa fa-user"></i> Perfil
							</Link>
							<div className="dropdown-divider"></div>
							<a className="dropdown-item" href="#logout" onClick={logout}>
								<i className="fa fa-sign-out-alt"></i> Salir
							</a>
						</div>
					</li>
				</ul>
			</nav>
		</header>
	);
};

export default Navbar;
