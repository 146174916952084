import { createContext, useContext, useCallback, useState, useEffect, useMemo } from "react";
import PocketBase from "pocketbase";
import { useInterval } from "usehooks-ts";
import jwtDecode from "jwt-decode";

const PocketContext = createContext({});

export const PocketProvider = ({ children }) => {
	const pocketbase = useMemo(() => new PocketBase("https://internal.contacta.mx/api/"), []);

	const [token, setToken] = useState(pocketbase.authStore.token);
	const [user, setUser] = useState(pocketbase.authStore.model);

	const [providers, setProviders] = useState(null);

	useEffect(() => {
		const fillProviders = async () => {
			const { authProviders } = await pocketbase.collection("users").listAuthMethods();
			setProviders(authProviders);
		};

		fillProviders();

		return pocketbase.authStore.onChange((token, model) => {
			setToken(token);
			setUser(model);
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const refreshSessions = useCallback(async () => {
		if (!pocketbase.authStore.isValid) return;

		const decoded = jwtDecode(token);
		const tokenExpiration = decoded.exp;
		const expirationWithBuffer = (decoded.exp + 60 * 1000 * 5) / 1000;
		if (tokenExpiration < expirationWithBuffer) {
			await pocketbase.collection("users").authRefresh();
		}
	}, [token, pocketbase]);

	const loginWithProvider = useCallback(
		async (params_code = "") => {
			const redirect_url = `${window.location.origin}/redirect`;
			const provider = JSON.parse(localStorage.getItem("provider"));

			return await pocketbase.collection("users").authWithOAuth2Code(provider.name, params_code, provider.codeVerifier, redirect_url, { emailVisibility: true });
		},
		[pocketbase]
	);

	const logout = useCallback(() => {
		pocketbase.authStore.clear();
	}, [pocketbase]);

	useInterval(refreshSessions, token ? 60 * 1000 * 2 : null);

	// return { pocketbase };
	return <PocketContext.Provider value={{ loginWithProvider, logout, providers, user, token, pocketbase }}>{children}</PocketContext.Provider>;
};

export const usePocket = () => useContext(PocketContext);
