import SidebarDropdown from "./SidebarDropdown";

const Sidebar = ({ isOpen }) => {
	const menus = [
		{
			icon: "fa-cog",
			name: "Contactos",
			path: "/contacts",
			items: [
				{ name: "Contactos", path: "/contacts", icon: "fa-users" },
				{ name: "Agregar", path: "/add", icon: "fa-user-plus" },
			],
		},
	];

	return (
		<div className={`sidebar py-3 bg-gray-700 ${isOpen ? "shrink show" : ""}`} id="sidebar">
			<h6 className="sidebar-heading">Menú Principal</h6>
			<ul className="list-unstyled">
				{menus.map((menu, i) => (
					<SidebarDropdown name={menu.name} icon={menu.icon} items={menu.items} path={menu.path} key={i} />
				))}
			</ul>
		</div>
	);
};

export default Sidebar;
