import React, { useEffect } from "react";
import { usePocket } from "../contexts/PocketContext";
import { Link, useNavigate } from "react-router-dom";

function LoginPage() {
	const { providers, user } = usePocket();
	const navigate = useNavigate();

	useEffect(() => {
		if (user) {
			navigate("/home");
		}
	}, [user, navigate]);

	const saveProvider = (provider) => {
		localStorage.setItem("provider", JSON.stringify(provider));
	};

	return (
		<>
			{providers?.map((provider, ix) => (
				<div key={ix} className="d-grid mb-1">
					<Link to={`${provider.authUrl + window.location.origin}/redirect`} onClick={() => saveProvider(provider)} className={`btn btn-${provider.name.indexOf("oidc") === -1 ? provider.name : "primary"} btn-lg capitalize mb-3`}>
						<i className={`${provider.name.indexOf("oidc") === -1 ? "fab fa-" + provider.name : "fa fa-lock"} fa-fw`}></i> {provider.name.indexOf("oidc") === -1 ? provider.name : "intranet"}
					</Link>
				</div>
			))}

			<p className="text-sm text-muted text-center">
				Incidencias &mdash; Información <Link to="mailto:soporte@contacta.mx">soporte&#064;contacta&#046;mx</Link>
			</p>
		</>
	);
}

export default LoginPage;
