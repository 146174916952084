import { useState } from "react";
import { Link } from "react-router-dom";

const SidebarDropdown = ({ name, icon, items, path }) => {
	const [open, setOpen] = useState(false);

	const handleDropdown = () => {
		setOpen(!open);
	};

	return (
		<li className="sidebar-list-item">
			<Link className={`sidebar-link text-white ${!open ? "collapsed" : ""}`} role="button" aria-expanded={!open ? "false" : "true"} data-bs-toggle="collapse" onClick={handleDropdown}>
				<i className={`fa ${icon}`}></i>
				<span className="sidebar-link-title px-3">{name} </span>
			</Link>
			<ul className={`sidebar-menu list-unstyled collapse ${open ? "show" : ""}`}>
				{items.map((item, i) => (
					<li className="sidebar-list-item" key={i}>
						<Link className="sidebar-link text-dark" to={path + item.path}>
							<i className={`fa ${item.icon} px-2`}></i> {item.name}
						</Link>
					</li>
				))}
			</ul>
		</li>
	);
};

export default SidebarDropdown;
